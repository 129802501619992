<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Mentors by Status page"
        data-cy="header-admin-reports-mentor-status"
        autofocus
      >
        Mentors by Status
      </h1>
      <div class="flex">
        <div class="flex-auto">
          <div class="mb-2">Status</div>
          <select
            class="mb-2 w-1/2 rounded border-dark-gray py-1"
            v-model="status"
          >
            <option
              v-for="status in lookup_mentor_status_options"
              :key="status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
        <div class="text-right flex-auto py-6">
          <Button
            class=""
            text="Download Data"
            @click="downloadData('mentors_by_status.csv', records)"
          />
        </div>
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "MentorsByStatus",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      term: null,
      status: "ACTIVE",
      tableConfig: [
        {
          display: "Prefix",
          name: "prefix",
          sortable: false,
          class: "",
        },
        {
          display: "First Name",
          name: "first_name",
          sortable: false,
          class: "",
        },
        {
          display: "Middle Name",
          name: "middle_name",
          sortable: false,
          class: "",
        },
        {
          display: "Last Name",
          name: "last_name",
          sortable: false,
          class: "",
        },
        {
          display: "Suffix",
          name: "suffix",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Status",
          name: "mentor_status",
          sortable: false,
          class: "",
        },
        {
          display: "Pairing Status",
          name: "pairing_status",
          sortable: false,
          class: "",
        },
        {
          display: "Pref. Acad. Lvl.",
          name: "acad_level",
          sortable: false,
          class: "",
        },
        {
          display: "Alumnus Year",
          name: "alumnus_year",
          sortable: false,
          class: "",
        },
        {
          display: "Employer Name",
          name: "employer_name",
          sortable: false,
          class: "",
        },
        {
          display: "Position",
          name: "position",
          sortable: false,
          class: "",
        },
        {
          display: "Email Type",
          name: "email_type",
          sortable: false,
          class: "",
        },
        {
          display: "Email Address",
          name: "email_address",
          sortable: false,
          class: "",
        },
        {
          display: "Phone Number Type",
          name: "phone_type",
          sortable: false,
          class: "",
        },
        {
          display: "Phone Number",
          name: "phone_number",
          sortable: false,
          class: "",
        },
        {
          display: "Address Type",
          name: "address_type",
          sortable: false,
          class: "",
        },
        {
          display: "Address Line 1",
          name: "address_line_1",
          sortable: false,
          class: "",
        },
        {
          display: "Address Line 2",
          name: "address_line_2",
          sortable: false,
          class: "",
        },
        {
          display: "City",
          name: "city",
          sortable: false,
          class: "",
        },
        {
          display: "State",
          name: "state",
          sortable: false,
          class: "",
        },
        {
          display: "Zip Code",
          name: "zip_code",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(() => {
      this.term = this.lookup_current_term;
      this.getData();
      this.$watch(
        (vm) => vm.status,
        () => {
          this.isLoading = true;
          this.getData();
        },
        { immediate: false }
      );
    });
  },
  methods: {
    downloadData,
    getData() {
      let url = `admin/reports/mentors_status?term=${this.term.term_id}`;
      if (this.status) {
        url += `&status=${this.status.replace(/\s/g, "_").toUpperCase()}`;
      }
      get(url)
        .then((results) => {
          this.records = results.map((item) => {
            item.pairing_status = item.pairing_status ? "Paired" : "Unpaired"
            return item
          });
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_mentor_status_options", "lookup_current_term"]),
  },
};
</script>

 <style scoped></style>
